import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Wrapper from "../components/Wrapper";
import Footer from "../components/Footer";
import Content, { HTMLContent } from "../components/Content";
import Hero from "../components/Hero";
import blogSvg from "../images/blog_svg.svg";
import ContactUs from "../components/ContactUs";
import Line from "../components/Line";
import { localStorageKeys } from "../utility/localstorage";
import { getTranslatedText } from "../utility/constant";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="blog-post">
      {helmet || ""}
      <div className="blog-post__line-wrapper">
        <Line type="large" />
      </div>
      <h2 className="blog-post__title">{title}</h2>
      <p className="blog-post__text">{description}</p>

      <PostContent content={content} />
      {/* {tags && tags.length ? (
        <div style={{ marginTop: `4rem` }}>
          <h4>Tags</h4>
          <ul className='taglist'>
            {tags.map((tag) => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null} */}
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <Hero
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
        headlineOne={getTranslatedText(
          myCustomAppState.language,
          "Blog BannerHeading1"
        )}
        headlineTwo={getTranslatedText(
          myCustomAppState.language,
          "Blog BannerHeading2"
        )}
        headlineThree={getTranslatedText(
          myCustomAppState.language,
          "Blog BannerHeading3"
        )}
        buttonLabel={getTranslatedText(
          myCustomAppState.language,
          "Banner DownloadButton"
        )}
        rightColImage={blogSvg}
      />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
      <ContactUs
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
    </Wrapper>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
